import React from 'react'
import dynamic from 'next/dynamic'

const CarbonOffsetList = dynamic(
  () => import('@frontend/domains/iframe/carbon-offset').then((module) => module.CarbonOffsetList),
  { ssr: false },
)

const CarbonOffsetListPage = () => <CarbonOffsetList />

export default CarbonOffsetListPage
